<template>
  <div class="content">
    <div class="card">
      <el-button type="primary" @click="handleAdd(0)" icon="el-icon-plus">添加</el-button>
      <el-button type="primary" plain @click="handleAdd(0)" icon="el-icon-upload2">导出</el-button>
      <div class="line"></div>
      <el-select placeholder="请选择公司" style="margin-right: 10px">
        <template v-for="(item, index) in hierarchy_list">
          <el-option :label="item.name" :value="item.hierarchy_id" :key="index"></el-option>
        </template>
      </el-select>
      <el-select placeholder="请选择门店">
        <template v-for="(item, index) in store_list">
          <el-option :label="item.name" :value="item.hierarchy_id" :key="index"></el-option>
        </template>
      </el-select>
      <el-button type="primary" style="margin-left:10px;">搜索</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" stripe>
        <el-table-column align="center" prop="merchant" label="公司名称"></el-table-column>
        <el-table-column align="center" prop="shop" label="门店名称"></el-table-column>
        <el-table-column
          align="center"
          prop="ha_type_text"
          label="设备类型"
          column-key="ha_type_text"
          :filters="[
        {text: '收银机', value: '收银机'},
        {text: '扫码枪', value: '扫码枪'},
        {text: '盘点枪', value: '盘点枪'},
        ]"
          :filter-method="filterHandler"
        ></el-table-column>
        <el-table-column align="center" prop="ha_sn" label="SN"></el-table-column>
        <el-table-column align="center" prop="create_time" label="添加时间"></el-table-column>
        <el-table-column align="center" fixed="action" prop="operation" label="操作"></el-table-column>
      </el-table>
      <div class="pagina">
        <el-pagination background layout="prev, pager, next" :total="total"></el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="visible"
      width="45%"
      title="新增设备"
      :destroy-on-close="true"
      :before-close="handleCance"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="门店名称" :label-width="shopFormLabelWidth" prop="hierarchy_id">
          <el-select placeholder="请选择门店" v-model="form.hierarchy_id">
            <template v-for="(item, index) in store_list">
              <el-option :label="item.name" :value="item.hierarchy_id" :key="index"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <div v-for="(item, index) in accessory" :key="index">
          <el-row>
            <el-col :span="8">
              <el-form-item label="设备类型" :label-width="shopFormLabelWidth" :required="true">
                <el-select v-model="item.ha_type" placeholder="请选择设备类型" style="width: 216px">
                  <el-option label="收银机" value="10"></el-option>
                  <el-option label="扫码枪" value="20"></el-option>
                  <el-option label="盘点枪" value="30"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="SN" :label-width="shopFormLabelWidth" :required="true">
                <el-input v-model="item.ha_sn" autocomplete="off" placeholder="请输入SN"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-button
                type="primary"
                style="margin-left: 20px"
                v-if="index === 0"
                @click="handleAccessAdd(index)"
              >添加</el-button>
              <el-button
                type="primary"
                style="margin-left: 20px"
                v-if="index !== 0"
                @click="handleAccessDel(index)"
              >删除</el-button>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div style="text-align:right">
        <el-button type="primary" @click="submit('form')">保存</el-button>
        <el-button @click="handleCance">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deviceList, deviceAdd } from "@/api/businessManage/business";
import { getSelectList } from "@/api/agentManage/index";

export default {
  data() {
    return {
      tableData: [],
      total: 0,
      visible: false,
      form: {},
      shopFormLabelWidth: "100px",
      hierarchy_list: [],
      store_list: [],
      accessory: [
        {
          ha_type: "",
          ha_sn: "",
        },
      ],
      shopFormLabelWidth: "100px",
      rules: {
        hierarchy_id: [
          {
            required: true,
            message: "请选择门店",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.fetechData();
    this.getSelectList();
    this.getStoreList();
  },
  methods: {
    /*
     *  设备列表
     */
    fetechData() {
      deviceList().then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  关联商家数据
     */
    getSelectList() {
      const data = {
        type: 2,
      };
      getSelectList(data).then((res) => {
        if (res.code === 1) {
          if (res.data && res.data.length !== 0) {
            this.hierarchy_list = res.data;
          }
        }
      });
    },
    /*
     *   获取门店信息
     */
    getStoreList() {
      const data = {
        type: 3,
      };
      getSelectList(data).then((res) => {
        if (res.code === 1) {
          if (res.data && res.data.length !== 0) {
            this.store_list = res.data;
          }
        }
      });
    },
    /*
     *  新增设备
     */
    handleAdd() {
      this.visible = true;
    },
    /*
     *  删除设备信息
     */
    handleAccessDel(index) {
      this.accessory = this.accessory.filter(
        (item, indexs) => indexs !== index
      );
    },
    /*
     *  新增设备信息
     */
    handleAccessAdd() {
      this.accessory.push({
        ha_type: "",
        ha_sn: "",
      });
    },
    /*
     *   关闭弹窗
     */
    handleCance() {
      this.form = {};
      this.accessory = [
        {
          ha_type: "",
          ha_sn: "",
        },
      ];
      this.visible = false;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    /*
     *  提交设备信息
     */
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = { ...this.form };
          form.accessory = this.accessory;

          deviceAdd(form).then((res) => {
            if (res.code === 1) {
              this.handleCance();
              this.$message({
                message: "新增设备成功",
                type: "success",
              });
              this.fetechData();
            }
          });
        } else {
          this.$message({
            message: "请检查必填项信息",
            type: "warning",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .line {
  display: inline-block;
  vertical-align: middle;
  margin: 0 30px;
  width: 1px;
  height: 40px;
  background: #e9e9e9;
}
</style>
