import { post } from '@/utils/request';
// 商家列表
const getList = (data) => {
    return post({
        url: '/admin/hierarchy.Merchant/list',
        data: data
    })
}

// 商家添加
const add = (data) => {
    return post({
        url: '/admin/hierarchy.Merchant/create',
        data: data
    })
}

// 商家审核
const merchantAudit = (data) => {
    return post({
        url: '/admin/hierarchy.Merchant/merchantAudit',
        data: data
    })
}

// 商家详情
const merchantDetail = (data) => {
    return post({
        url: '/store/system.Shop/detail',
        data: data
    })
}


// 门店列表
const shopList = (data) => {
    return post({
        url: '/admin/hierarchy.Shop/list',
        data: data
    })
}

// 门店添加
const shopAdd = (data) => {
    return post({
        url: '/admin/hierarchy.Shop/create',
        data: data
    })
}

// 门店详情
const shopDetails = (data) => {
    return post({
        url: '/admin/hierarchy.Shop/detail',
        data: data
    })
}

// 设备列表
const deviceList = (data) => {
    return post({
        url: '/admin/hierarchy.Accessory/list',
        data: data
    })
}

// 添加设备
const deviceAdd = (data) => {
    return post({
        url: '/admin/hierarchy.Accessory/create',
        data: data
    })
}

// 门店更新
const shopUpdate = (data) => {
    return post({
        url: '/store/system.Shop/update',
        data: data
    })
}

//名下店铺列表
const getStoreList=data=>{
    return post({
        url:'/store/system.Shop/list',
        data,
    })
}


export {
    getStoreList,
    getList,
    add,
    merchantAudit,
    shopList,
    shopAdd,
    shopDetails,
    deviceList,
    deviceAdd,
    merchantDetail,
    shopUpdate,
}