import { post } from '@/utils/request';
// 代理商列表
const getList = (data) => {
  return post({
    url: '/admin/hierarchy.Agent/list',
    data: data
  })
}

// 省市区下拉接口
const getRegionList = (data) => {
  return post({
    url: '/store/common.Region/getRegionList',
    data: data
  })
}

// 创建代理商
const createRegion = (data) => {
  return post({
    url: '/admin/hierarchy.Agent/create',
    data: data
  })
}

// 代理商下拉
const getSelectList = (data) => {
  return post({
    url: '/admin/hierarchy.Agent/getSelectList',
    data: data
  })
}

//终止合作
const stopCooperate = (data) => {
  return post({
    url: '/admin/hierarchy.Agent/stopCooperate',
    data: data
  })
}

// 审核
const agentAudit = (data) => {
  return post({
    url: '/admin/hierarchy.Agent/agentAudit',
    data: data
  })
}
export {
  getList,
  getRegionList,
  createRegion,
  getSelectList,
  stopCooperate,
  agentAudit
};
